<template>
  <div>
    <v-btn
      v-if="$admin.can('route-planning.add')"
      color="primary"
      dark
      @click="openModal"
    >
      {{ $t("add_new") }}
    </v-btn>
    <v-dialog
      v-if="isVisibleDialog"
      v-model="isVisibleDialog"
      persistent
      max-width="80vw"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h3">{{ $t("add_new") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="2">
                  <select-date
                    v-model="options.date"
                    :label="$t('select_date_*')"
                    :min="dateValidation('min')"
                    :max="dateValidation('max')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="2">
                  <v-select
                    v-model="options.vehcile_assignment"
                    dense
                    :label="$t('vehicle_assignment_*')"
                    :items="['manual', 'auto']"
                    :rules="[rules.required]"
                    @change="() => delete options.vehicle"
                  />
                </v-col>
                <v-col
                  v-if="options.vehcile_assignment == 'manual'"
                  cols="3"
                >
                  <v-select
                    v-model="options.vehicle"
                    dense
                    :label="$t('select_vehicle_*')"
                    :items="vehicleList"
                    item-value="vehicle_id"
                    item-text="vehicle_name"
                    :rules="[rules.required]"
                  />
                </v-col>

                <!-- <v-col cols="3">
                  <v-text-field
                    v-model="options.client_name"
                    dense
                    disabled
                    :label="$t('select_client_*')"
                    :rules="[rules.required]"
                  />
                </v-col> -->
                <v-col cols="2">
                  <v-select
                    v-model="options.optimize_sequence"
                    dense
                    :label="$t('optimize_sequence_*')"
                    :items="['yes', 'no']"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="2">
                  <v-select
                    v-model="options.return_to_start"
                    dense
                    :label="$t('return_to_start_location_*')"
                    :items="['yes', 'no']"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col cols="12">
                  <add-new-job-table
                    v-model="options.jobs"
                    :items="options"
                  />
                  <!-- :locations="getLocations()" -->
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="false"
              @click="saveItem()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  components: {
    selectDate: () => import("@/components/base/SingleDatePicker.vue"),
    AddNewJobTable: () => import("./AddNewJobTable.vue"),
  },
  mixins: [validationRules],
  props: {
    tenantId: {
      type: [Number, String],
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      isVisibleDialog: false,
      options: {
        jobs: [{}],
      },
    };
  },
  computed: {
    ...mapGetters({
      vehicleList: "routePlaning/getVehicleList",
      clientList: "routePlaning/getClientList",
    }),
  },
  watch: {
    "options.vehicle": {
      handler: function (v) {
        if (v) {
          const row = this.vehicleList.find((r) => r.vehicle == v);
          this.options.client = row.client;
          this.options.client_name = row.client_name;
        }
      },
    },
  },
  // mounted() {
  // },
  methods: {
    async saveItem() {
      if (this.$refs.form.validate()) {
        this.isVisibleDialog = false;
        this.options.vehicle_id = this.options.vehicle;
        const params = {
          ...this.options,
          tenant_id: this.tenantId,
        };
        await this.$store
          .dispatch("routePlaning/addJob", params)
          .then((res) => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            this.$emit("onSave", res);
            this.closeForm();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
          });
      }
    },

    //
    openModal() {
      this.isVisibleDialog = true;
      this.options.date = this.getCurrentDate();
    },
    closeForm() {
      // this.$refs.form.reset();
      this.options = {
        jobs: [{}],
      };
      this.isVisibleDialog = false;
    },
    //
    getLocations() {
      const arr = this.clientList.find((r) => this.options.client === r.id);
      return arr?.client_location;
    },
    //
    getCurrentDate() {
      const dt = new Date();
      const y = dt.getFullYear();
      let m = dt.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;

      let d = dt.getDate();
      d = d < 10 ? `0${d}` : d;

      return `${y}-${m}-${d}`;
    },
    dateValidation(v) {
      const d = new Date();
      let day = d.getDate();
      if (v === "max") day += 1;
      day = day < 10 ? `0${day}` : day;

      let m = d.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;

      const y = d.getFullYear();
      const value = `${y}-${m}-${day}`;
      return value;
    },
  },
};
</script>
<style lang="sass" scoped>
.container
  max-width: inherit !important
</style>
